import React from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import MultiSelect from "react-multi-select-component";

import ManaPreviewTextbox from "./ManaPreviewTextbox"

const CARD_TYPES = [
  "Artifact",
  "Creature",
  "Enchantment",
  "Land",
  "Instant",
  "Sorcery",
  "Planeswalker",
  "Enchantment Saga"
];

// These card types require alternate rules text form fields to be visible
const SPECIAL_TYPES = [
  "Planeswalker",
  "Enchantment Saga"
]

// From mtg.design API: last updated 11/17/23
const CARD_LAYOUTS = {
  regular: "Regular",
  saga: "Saga",
  conspiracy: "Conspiracy Draft",
  "transform-modal_front": "Modal DFC: Front",
  "transform-modal_back": "Modal DFC: Back",
  "transform-sun": "Double-Faced: Sun (Front)",
  "transform-moon": "Double-Faced: Moon (Back)",
  "transform-compass": "Double-Faced: Compass (Front)",
  "transform-land": "Double-Faced: Land (Back)",
  "transform-mooneldrazi": "Double-Faced: Eldrazi (Front)",
  "transform-eldrazi": "Double-Faced: Eldrazi (Back)",
  "transform-origin": "Double-Faced: Origin (Front)",
  "transform-spark": "Double-Faced: Spark (Back)",
  mgdpromo: "Full Art Promo",
  token: "Token"
}

/**
 * An "interface" that requires a handleSubmit hook
 */
const ICardForm = ({ handleSubmit, setFormToObject, card }) => {
  // Convenience to simplify default || logic in form fields
  card = card || {};
  const [selectedTypes, setSelectedTypes] = React.useState(
    (card.type || []).map((type) => ({ label: type, value: type }))
  );

  React.useEffect(() => {
    setFormToObject((target) => {
      const data = new FormData(target);
      const object = {};
      data.forEach((value, key) => {
        object[key] = value;
      });
      object.type = selectedTypes.map((type) => type.value);

      return object;
    });
  }, [setFormToObject, selectedTypes]);

  return (
    <Container>
      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={3}>
            <Form.Control
              name="number"
              defaultValue={card.number || ""}
              placeholder="Card #"
            />
          </Col>
          <Col md={3}>
            <Form.Control
              name="set"
              defaultValue={card.set || ""}
              placeholder="Set"
            />
          </Col>
          <Col md={6}>
            <Form.Control
              name="title"
              defaultValue={card.title || ""}
              placeholder="Card Title"
            />
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <ManaPreviewTextbox card={card}/>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <Form.Group>
              <Form.Control
                name="superType"
                defaultValue={card.superType || ""}
                placeholder="Supertype (i.e. Legendary)"
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <MultiSelect
                options={CARD_TYPES.map((type) => ({
                  label: type,
                  value: type,
                }))}
                placeholder="Card Type"
                onChange={setSelectedTypes}
                value={selectedTypes}
                name="type"
              />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Control
              name="subType"
              defaultValue={card.subType || ""}
              placeholder="Subtype"
            />
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <Form.Group>
              <Form.Control name="rarity" as="select" custom>
                <option value="C" selected={card.rarity === "C"}>
                  Common
                </option>
                <option value="U" selected={card.rarity === "U"}>
                  Uncommon
                </option>
                <option value="R" selected={card.rarity === "R"}>
                  Rare
                </option>
                <option value="M" selected={card.rarity === "M"}>
                  Mythic
                </option>
              </Form.Control>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              { selectedTypes.map((type) => type.value).includes("Land") &&
                <>
                  <Form.Control name="landOverlay" as="select" custom>
                    <option value="C" selected={card.landOverlay === "C"}>
                      Gold
                    </option> 
                    <option value="W" selected={card.landOverlay === "W"}>
                      White
                    </option>
                    <option value="WU" selected={card.landOverlay === "WU"}>
                      White-Blue
                    </option>
                    <option value="WB" selected={card.landOverlay === "WB"}>
                      White-Black
                    </option>
                    <option value="U" selected={card.landOverlay === "U"}>
                      Blue
                    </option>
                    <option value="UB" selected={card.landOverlay === "UB"}>
                      Blue-Black
                    </option>
                    <option value="UR" selected={card.landOverlay === "UR"}>
                      Blue-Red
                    </option>
                    <option value="B" selected={card.landOverlay === "B"}>
                      Black
                    </option>
                    <option value="BR" selected={card.landOverlay === "BR"}>
                      Black-Red
                    </option>
                    <option value="BG" selected={card.landOverlay === "BG"}>
                      Black-Green
                    </option>
                    <option value="R" selected={card.landOverlay === "R"}>
                      Red
                    </option>
                    <option value="RG" selected={card.landOverlay === "RG"}>
                      Red-Green
                    </option>
                    <option value="RW" selected={card.landOverlay === "RW"}>
                      Red-White
                    </option>
                    <option value="G" selected={card.landOverlay === "G"}>
                      Green
                    </option>
                    <option value="GW" selected={card.landOverlay === "GW"}>
                      Green-White
                    </option>
                    <option value="GB" selected={card.landOverlay === "GB"}>
                      Green-Blue
                    </option>
                  </Form.Control>
                </>
              }
            </Form.Group>
          </Col>
        </Row>
        <Row>
          { !selectedTypes.map((type) => type.value).includes("Planeswalker") &&
            <>
              <Col md={2} lg={1}>
                <Form.Control
                  name="power"
                  defaultValue={card.power || ""}
                  placeholder="P"
                />
              </Col>
              {"/"}
              <Col md={2} lg={1}>
                <Form.Control
                  name="toughness"
                  defaultValue={card.toughness || ""}
                  placeholder="T"
                />
              </Col>
            </>
          }
          { selectedTypes.map((type) => type.value).includes("Planeswalker") &&
            <> 
              <Col>
                <Form.Control
                  name="startingLoyalty"
                  defaultValue={card.startingLoyalty || ""}
                  placeholder="Starting Loyalty Count"
                />
              </Col>
            </>
          }
          <Col>
            <Form.Control
              name="setSymbol"
              defaultValue={card.setSymbol || ""}
              placeholder="Set Symbol URL"
            />
          </Col>
          <Col> 
            <Form.Control
              name="textSize"
              defaultValue={card.textSize || ""}
              placeholder="Text Size"
            />
          </Col>
          <Col> 
            <Form.Control
              name="cycleId"
              defaultValue={card.cycleId || ""}
              placeholder="Cycle ID"
            />
          </Col>
        </Row>
        <Row>
          <Col md={12}>
          <Form.Group>
              <Form.Control name="frameLayout" as="select" custom>
                {Object.entries(CARD_LAYOUTS).map(([key, value]) => (
                  <option value={key} selected={card.frameLayout === key}>
                    {value}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <Form.Group>
              { /* Check to see if rules text fields should be rendered normally */ }
              { selectedTypes.every((type) => !SPECIAL_TYPES.includes(type.value)) &&
                <>
                  <Form.Label>Rules Text</Form.Label>
                  <Form.Control
                    name="rulesText"
                    defaultValue={card.rulesText || ""}
                    as="textarea"
                    rows="3"
                  />
                </>
              }
              { selectedTypes.map((type) => type.value).includes("Planeswalker") &&
                <>
                  <Form.Label>Planeswalker Abilities</Form.Label>
                  <Row>
                    <Col md={2}>
                      <Form.Control 
                        name="loyaltyCt1"
                        defaultValue={card.loyaltyCt1 || ""}
                        placeholder={"+/- 1"} />
                    </Col>
                    <Col>
                      <Form.Control 
                        name="rulesText"
                        defaultValue={card.rulesText || ""} />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={2}>
                      <Form.Control 
                        name="loyaltyCt2"
                        defaultValue={card.loyaltyCt2 || ""} 
                        placeholder={"+/- 1"} />
                    </Col>
                    <Col>
                      <Form.Control 
                        name="pwText2"
                        defaultValue={card.pwText2 || ""} />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={2}>
                      <Form.Control 
                        name="loyaltyCt3"
                        defaultValue={card.loyaltyCt3 || ""} 
                        placeholder={"+/- 1"} />
                    </Col>
                    <Col>
                      <Form.Control 
                        name="pwText3"
                        defaultValue={card.pwText3 || ""} />
                    </Col>
                  </Row>
                </>
              }
              { selectedTypes.map((type) => type.value).includes("Enchantment Saga") &&
                <>
                  <Form.Label>Chapter Abilities</Form.Label>
                  <Row>
                    <Col>
                      <Form.Control 
                        name="rulesText"
                        defaultValue={card.rulesText || ""}
                        placeholder={"Chapter Ability 1"} />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Control 
                        name="pwText2"
                        defaultValue={card.pwText2 || ""}
                        placeholder={"Chapter Ability 2"} />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Control 
                        name="pwText3"
                        defaultValue={card.pwText3 || ""}
                        placeholder={"Chapter Ability 3"} />
                    </Col>
                  </Row>
                </>
              }
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group>
              <Form.Label>Flavor Text</Form.Label>
              <Form.Control
                name="flavorText"
                defaultValue={card.flavorText || ""}
                as="textarea"
                rows="3"
              />
            </Form.Group>
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <Form.Control
              name="artUrl"
              defaultValue={card.artUrl || ""}
              placeholder="Art URL"
            />
          </Col>
          <Col>
            <Form.Control
              name="artist"
              defaultValue={card.artist || ""}
              placeholder="Artist Name"
            />
          </Col>
        </Row>
        <Button type="submit">Submit</Button>
      </Form>
    </Container>
  );
};

export default ICardForm;
