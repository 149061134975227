import React from "react";

import ProgressBar from "react-bootstrap/ProgressBar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Mana } from "@saeris/react-mana";

import { extractPrimaryType } from "../utils";

import "./CardTypeStats.css";
import "./Mana.css";

// Breaks for Enchantment Creatures
const TYPES = [
  "Artifact",
  "Creature",
  "Enchantment",
  "Instant",
  "Land",
  "Planeswalker",
  "Sorcery",
];

// Constructor function to count card types in an array
function TypeCounter(cards) {
  cards
    // Get the main type of the card
    .map((card) => extractPrimaryType(card.type))
    .forEach((val) => (this[val] = (this[val] || 0) + 1));
}

const CardTypeStats = ({ cards, color }) => {
  const typeCounts = new TypeCounter(cards);
  const totalCards = cards.length;
  console.log(typeCounts);
  return (
    <div>
      {TYPES.map((type) => (
        <Row className="align-items-center progress-bar-entry">
          <Col md="auto">
            <Mana symbol={type.toLowerCase()} />
          </Col>
          <Col >
            <ProgressBar>
              <ProgressBar
                key={type}
                className={`mana-color mana-${color}`}
                now={((typeCounts[type] || 0) / totalCards) * 100}
                label={typeCounts[type]}
              />
            </ProgressBar>
          </Col>
        </Row>
      ))}
    </div>
  );
};

export default CardTypeStats;
