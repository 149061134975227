import React from "react";

import TotalManaStats from "./stats/TotalManaStats";
import CardTypeStats from "./stats/CardTypeStats";

import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { Mana } from "@saeris/react-mana";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk, faCircle } from "@fortawesome/free-solid-svg-icons";

import { manaCostToRaw } from "./utils";

import "./stats/Mana.css";
import CardRarityStats from "./stats/CardRarityStats";

const CardStats = ({ cards }) => {
  const [color, setColor] = React.useState("a");

  const cardColorProps = {
    color,
    cards: cards.filter(
      (card) => color === "a" || color === manaCostToRaw(card.manaCost)
    ),
  };

  const ManaButton = ({ color: buttonColor }) => (
    <Button
      className={`mana-color mana-${buttonColor}`}
      onClick={() => setColor(buttonColor)}
      active={color === buttonColor}
    >
      <Mana symbol={buttonColor} />
    </Button>
  );

  return (
    <>
      <h4>Card Color Spread</h4>
      <TotalManaStats cards={cards} />
      <h4>Color Stats</h4>
      <ButtonGroup className="mana-buttons">
        <ManaButton color="w" />
        <ManaButton color="u" />
        <ManaButton color="b" />
        <ManaButton color="r" />
        <ManaButton color="g" />
        <Button
          className={`mana-color mana-m`}
          onClick={() => setColor("m")}
          active={color === "m"}
          title="Multicolored"
        >
          <FontAwesomeIcon icon={faCircle} />
        </Button>
        <ManaButton color="c" />
        <Button
          className={`mana-color mana-a`}
          onClick={() => setColor("a")}
          active={color === "a"}
          title="All Colors"
        >
          <FontAwesomeIcon icon={faAsterisk} />
        </Button>
      </ButtonGroup>
      <h5>Card Types</h5>
      <CardTypeStats {...cardColorProps} />
      <h5>Card Rarities</h5>
      <CardRarityStats {...cardColorProps} />
    </>
  );
};

export default CardStats;
