// Function to grab the representative color of the card. "c" is colorless, and "m" is multicolored.
export const manaCostToRaw = (manaCost) => {
  const manaSymbols = new Set(manaCost.replace(/[^a-z]/gi, "").split(""));

  switch (manaSymbols.size) {
    case 0:
      return "c";
    case 1:
      return manaSymbols.values().next().value.toLowerCase();
    default:
      return "m";
  }
};

export const extractPrimaryType = (type) => {
  if (type.length === 0) {
    return null;
  }

  if (type.includes("Creature")) {
    return "Creature";
  }

  if (type[0] === "Enchantment Saga") {
    return "Enchantment";
  }

  return type[0];
}