import React from "react";

import ProgressBar from "react-bootstrap/ProgressBar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import "./CardTypeStats.css";
import "./Rarity.css";

// Breaks for Enchantment Creatures
const RARITIES = ["c", "u", "r", "m"];

// Constructor function to count card types in an array
function RarityCounter(cards) {
  cards
    .map((card) => card.rarity.toLowerCase())
    .forEach((val) => (this[val] = (this[val] || 0) + 1));
}

const CardRarityStats = ({ cards }) => {
  const rarityCounts = new RarityCounter(cards);
  const totalCards = cards.length;

  return (
    <div>
      {RARITIES.map((rarity) => (
        <Row className="align-items-center progress-bar-entry">
          <Col md="auto">
            <img
              className="rarity-icon"
              src={`https://champaign-brews.s3.amazonaws.com/${
                rarity !== "c" ? rarity : ""
              }setsymb.png`}
            />
          </Col>
          <Col>
            <ProgressBar>
              <ProgressBar
                key={rarity}
                className={`rarity-color rarity-${rarity}`}
                now={((rarityCounts[rarity] || 0) / totalCards) * 100}
                label={rarityCounts[rarity]}
              />
            </ProgressBar>
          </Col>
        </Row>
      ))}
    </div>
  );
};

export default CardRarityStats;
