import React from "react";

import axios from "axios";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

class DeleteCardBox extends React.Component {
  handleSubmit = (event, card) => {
    const { destroyCard, clearDelete } = this.props;
    event.preventDefault();
    destroyCard(axios.delete(`/api/card/${card._id}`), card._id);
    clearDelete();
  };

  render() {
    const { card, clearDelete } = this.props;
    return (
      <Modal show={!!card} onHide={clearDelete}>
        <Modal.Title>Deleting {card && card.title}</Modal.Title>
        <Modal.Body>
          Are you sure you would like to delete {card && card.title}?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={clearDelete}>
            Close
          </Button>
          <Button variant="danger" onClick={(e) => this.handleSubmit(e, card)}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default DeleteCardBox;
