import React from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { Mana } from "@saeris/react-mana";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

const renderManaSymbols = (userInput) => {
  const normalizedUserInput = userInput.toLowerCase().replace(" ", "");
  if (
    !/^({((w\/u)|(w\/b)|(u\/b)|(u\/r)|(b\/g)|(w\/u)|(r\/g)|(r\/w)|(g\/w)|(g\/u)|(2\/[wbugr])|([0-9]+))}|(b|u|w|r|g|[0-9]))*$/.test(
      normalizedUserInput
    )
  ) {
    return <FontAwesomeIcon icon={faExclamationTriangle} />;
  }
  let characterList = normalizedUserInput.split("");
  const groupedMana = [];
  while (characterList.length > 0) {
    let manaSymbol = characterList.shift();
    if (manaSymbol === "{") {
      const [manaSubgroup, ...rest] = characterList.join("").split("}");
      characterList = rest.join("").split("");
      manaSymbol = manaSubgroup.replace("/", "");
    }
    if (manaSymbol.length > 0) {
      groupedMana.push(manaSymbol);
    }
  }

  return groupedMana.map((manaValue) => (
    <Mana symbol={manaValue} shadow fixed />
  ));
};
const ManaPreviewTextbox = ({ card }) => {
  const [userInput, setUserInput] = React.useState("");

  return (
    <InputGroup className="mb-3">
      <Form.Control
        onChange={(event) => {
          setUserInput(event.target.value);
        }}
        placeholder="Mana Cost"
        defaultValue={card.manaCost || ""}
        name="manaCost"
      />
      <InputGroup.Append>
        <InputGroup.Text id="basic-addon2">
          {renderManaSymbols(userInput)}
        </InputGroup.Text>
      </InputGroup.Append>
    </InputGroup>
  );
};
export default ManaPreviewTextbox;
