import React from "react";

import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Fade from "react-bootstrap/Fade";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";

import "./Card.css";

const Card = ({ card, editCard, deleteCard }) => {
  const [shouldShowButtons, setShouldShowButtons] = React.useState(false);
  return (
    <div
      className="mtg-card"
      onMouseEnter={() => setShouldShowButtons(true)}
      onMouseLeave={() => setShouldShowButtons(false)}
    >
      <img src={card.image} alt={card.title} />
      <Fade in={shouldShowButtons}>
        <div className="card-action-bar">
          <ButtonGroup className={shouldShowButtons ? "hidden" : ""}>
            <Button variant="danger" onClick={() => deleteCard(card)}>
              <FontAwesomeIcon icon={faTrash} />
            </Button>
            <Button onClick={() => editCard(card)}>
              <FontAwesomeIcon icon={faEdit} />
            </Button>
          </ButtonGroup>
        </div>
      </Fade>
    </div>
  );
};

export default Card;
