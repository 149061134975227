import React from "react";

import axios from "axios";

import Modal from "react-bootstrap/Modal";

import ICardForm from "./ICardForm";

class EditCardForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formToObject: () => {},
    };
  }

  handleSubmit = (event, card) => {
    const { prependNewCard } = this.props;

    event.preventDefault();
    const object = this.state.formToObject(event.target);

    prependNewCard(axios.put(`/api/card/${card._id}`, object), card._id);
    this.props.clearEdit();
  };

  setFormToObject = (formToObject) => {
    this.setState({ formToObject });
  };

  render() {
    const { card, clearEdit } = this.props;
    return (
      <Modal show={!!card} onHide={clearEdit} size="lg">
        <Modal.Body>
          <ICardForm
            card={card}
            handleSubmit={(e) => this.handleSubmit(e, card)}
            setFormToObject={this.setFormToObject}
          />
        </Modal.Body>
      </Modal>
    );
  }
}

export default EditCardForm;
