import React from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import CardPlaceholder from "./CardPlaceholder";
import Card from "./Card";

import "./CardSpoilerGrid.css";

const COLORS = ['W', 'U', 'B', 'R', 'G']

const costToColorSet = (cost) => {
  return new Set(Array.from(cost.toUpperCase()).filter(c => COLORS.includes(c)))
}

const costToColors = (cost) => new Set(
  (cost || "").toLowerCase().replace(/[^A-Za-z]/g, "")
);


const COLOR_ORDERING = "WU/WB/UB/UR/BR/BG/RG/WR/WG/UG/WUB/UBR/BRG/WRG/WUG/WUR/UBG/WRB/URG/WBG".split('/').map((colors) => new Set([...colors]))

function setsAreEqual(set1, set2) {
  if (set1.size !== set2.size) {
    return false;
  }

  for (const value of set1) {
    if (!set2.has(value)) {
      return false;
    }
  }

  return true;
}

const CardSpoilerGrid = ({ spoilerViewType, ...props }) => {
  switch (spoilerViewType) {
    case "column":
      return <ColumnView {...props} />;
    default:
      return <DefaultView {...props} />;
  }
};

const DefaultView = ({ cards, editCard, deleteCard }) => (
  <Container className="card-grid">
    <Row className="justify-content-md-center">
      {cards.map((card) =>
        Object.keys(card).length > 0 ? (
          <Card
            key={card.id}
            card={card}
            editCard={editCard}
            deleteCard={deleteCard}
          />
        ) : (
          <CardPlaceholder />
        )
      )}
    </Row>
  </Container>
);

const ColumnView = ({ cards, spoilerViewType, editCard, deleteCard }) => {
  const monoColorCards = cards.filter((card) => card && costToColorSet(card.manaCost).size === 1)
  const monoColorCycles = [...(new Set(monoColorCards.map((card) => card.cycleId)))].filter(c => c && c.length > 0).sort((a, b) => a.localeCompare(b))
  const cardsByColor = Object.fromEntries(COLORS.map((color) => [color, monoColorCards.filter((card) => [...costToColorSet(card.manaCost)][0] === color)]))
  const cyclesColorColumns = Object.entries(cardsByColor).map(([color, colorCards]) => monoColorCycles.map((cycle) => colorCards.find((card) => card.cycleId === cycle)))
  const nonCycleColorColumns = Object.entries(cardsByColor).map(([color, cards]) => cards.filter((card) => card.cycleId === undefined))
  const mergedColorColumns = COLORS.map((color, i) => [...cyclesColorColumns[i], ...nonCycleColorColumns[i]])
  console.log(mergedColorColumns)
  return (
    <Container fluid className="card-grid">
      <Row className="justify-content-md-center">
        {
          mergedColorColumns.map((cards) => <Col md={3.1}>
            {cards.map((card) => card ? <Card key={card.id} card={card} editCard={editCard} deleteCard={deleteCard} /> : <div className="card-placeholder" />)}
          </Col>)
        }
      </Row>

      <hr />

      <Row>
        {cards.filter((card) => {
          let cardColors = new Set((card.manaCost || "").toLowerCase().replace(/[^A-Za-z]/g, ""));
          return cardColors.size > 1;

        })
          .sort((cardA, cardB) => {
            if (cardA.cycleId === undefined || cardB.cycleId === undefined) return COLOR_ORDERING.findIndex(set => setsAreEqual(set, costToColorSet(cardA.manaCost))) - COLOR_ORDERING.findIndex(set => setsAreEqual(set, costToColorSet(cardB.manaCost)));
            return cardB.cycleId.localeCompare(cardA.cycleId) || COLOR_ORDERING.findIndex(set => setsAreEqual(set, costToColorSet(cardA.manaCost))) - COLOR_ORDERING.findIndex(set => setsAreEqual(set, costToColorSet(cardB.manaCost)));

          })
          .map((card) =>
            <Card
              key={card.id}
              card={card}
              editCard={editCard}
              deleteCard={deleteCard}
            />
          )
        }
      </Row>

      <hr />

      <Row>
        {cards.map((card) =>
          Object.keys(card).length > 0 &&
            (card.manaCost || "").toLowerCase().replace(/[^A-Za-z]/g, "") === "" &&
            !card.type.includes("Land") ? (
            <Card
              key={card.id}
              card={card}
              editCard={editCard}
              deleteCard={deleteCard}
            />
          ) : null
        )}
      </Row>

      <hr />

      <Row>
        {cards.map((card) =>
          Object.keys(card).length > 0 && card.type.includes("Land") ? (
            <Card
              key={card.id}
              card={card}
              editCard={editCard}
              deleteCard={deleteCard}
            />
          ) : null
        )}
      </Row>
    </Container>
  );
};

export default CardSpoilerGrid;
