import React from "react";

import axios from "axios";

import ICardForm from "./ICardForm";

class CreateCardForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formToObject: () => {},
    };
  }

  handleSubmit = (event) => {
    const { prependNewCard } = this.props;

    event.preventDefault();
    const object = this.state.formToObject(event.target);

    prependNewCard(axios.post("/api/card", object));
  };

  setFormToObject = (formToObject) => {
    this.setState({ formToObject });
  };

  render() {
    return (
      <ICardForm
        handleSubmit={this.handleSubmit}
        setFormToObject={this.setFormToObject}
      />
    );
  }
}

export default CreateCardForm;
