import React from "react";

import ProgressBar from "react-bootstrap/ProgressBar";

import { manaCostToRaw } from "../utils";

import "./Mana.css";

const COLOR_ORDER = ["w", "u", "b", "r", "g", "m", "c"];

// Constructor function to count elements in an array
function ManaCounter(array) {
  array
    .map((card) => manaCostToRaw(card.manaCost))
    .forEach((val) => (this[val] = (this[val] || 0) + 1));
}

const TotalManaStats = ({ cards }) => {
  // Get the proportion of cards for each color
  const manaCounts = new ManaCounter(cards);
  return (
    <ProgressBar>
      {COLOR_ORDER.filter((c) => manaCounts.hasOwnProperty(c)).map((mana) => (
        <ProgressBar
          key={mana}
          now={(manaCounts[mana] / cards.length) * 100}
          className={`mana-color mana-${mana}`}
          label={manaCounts[mana]}
        />
      ))}
    </ProgressBar>
  );
};

export default TotalManaStats;
