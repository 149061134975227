import React from "react";

import Spinner from "react-bootstrap/Spinner";

import "./CardPlaceholder.css";

const CardPlaceholder = () => (
  <div className="card-placeholder">
    <Spinner animation="border" />
  </div>
);

export default CardPlaceholder;
