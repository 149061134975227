import React from "react";

import axios from "axios";

import Collapse from "react-bootstrap/Collapse";
import Container from "react-bootstrap/Container";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Form from 'react-bootstrap/Form';
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlusSquare,
  faChartPie,
  faChartBar,
  faColumns
} from "@fortawesome/free-solid-svg-icons";

import CreateCardForm from "./CreateCardForm";
import CardSpoilerGrid from "./CardSpoilerGrid";
import CardStats from "./CardStats";
import EditCardForm from "./EditCardForm";
import DeleteCardBox from "./DeleteCardBox";

import _debounce from 'lodash/debounce';

import "./App.css";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cards: [],
      editingCard: null,
      deletingCard: null,
      showStats: false,
      showCreate: false,
      spoilerViewType: "default",
      set: ""
    };

    this.prependNewCard = this.prependNewCard.bind(this);
  }

  prependNewCard(promise, updateId) {
    this.setState(({ cards }) => ({
      cards: updateId
        ? // If update, replace the old spot of the card w/ placeholder
        cards.map((card) => (card._id === updateId ? {} : card))
        : // If create, append a new placeholder
        [{}, ...cards],
    }));
    promise
      .then(({ data }) => {
        this.setState(({ cards }) => ({
          cards: updateId
            ? cards.map((card) =>
              Object.keys(card).length === 0 ? data : card
            )
            : [data, ...cards.slice(1)],
        }));
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  destroyCard = (promise, cardId) => {
    const cardIdx = this.state.cards.findIndex((card) => card._id === cardId);
    // Could replace this with a slice, but until JS makes it easier to
    // replace not in place, oh well
    this.setState(({ cards }) => ({
      cards: cards.map((card) => (card._id === cardId ? {} : card)),
    }));

    promise
      .then(() => {
        // Remove the placeholder
        this.setState(({ cards }) => ({
          cards: cards.filter((_, i) => i !== cardIdx),
        }));
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  editCard = (card) => {
    this.setState({
      editingCard: card,
    });
  };

  clearEdit = () => {
    this.setState({
      editingCard: null,
    });
  };

  deleteCard = (card) => {
    this.setState({
      deletingCard: card,
    });
  };

  clearDelete = () => {
    this.setState({
      deletingCard: null,
    });
  };

  componentDidMount() {
    const storedSet = localStorage.getItem('storedSet')
    this.setState({ set: storedSet })

    axios.get("/api/card", {
      params: {
        set: storedSet
      }
    }).then((response) =>
      this.setState({
        cards: response.data,
      })
    );

  }

  changeSet = (set) => {
    this.setState({ set })

    const delayedChangeSetHandler = _debounce((value) => {
      localStorage.setItem('storedSet', set)
      axios.get("/api/card", {
        params: {
          set: value,
        }
      }).then((response) =>
        this.setState({
          cards: response.data,
        })
      );
    }, 1000)

    delayedChangeSetHandler(set)
  }

  render() {
    const {
      cards,
      editingCard,
      deletingCard,
      showStats,
      showCreate,
      spoilerViewType
    } = this.state;

    return (
      <div className="App">
        <Collapse in={showCreate}>
          <div className="app-content">
            <h1>Create new Card</h1>
            <CreateCardForm prependNewCard={this.prependNewCard} />
          </div>
        </Collapse>
        <Collapse in={showStats}>
          <Container className="app-content">
            <h1>Card Stats</h1>
            <CardStats
              cards={cards.filter((card) => Object.keys(card).length > 0)}
            />
          </Container>
        </Collapse>
        {(showCreate || showStats) && <hr />}
        <div class="app-content">
          <ButtonGroup className="header-btn-grp">
            <Button
              variant="success"
              onClick={() => this.setState({ showCreate: !showCreate })}
              active={showCreate}
            >
              <FontAwesomeIcon icon={faPlusSquare} /> Create
            </Button>
            <Button
              variant="info"
              onClick={() => this.setState({ showStats: !showStats })}
              active={showStats}
            >
              <FontAwesomeIcon icon={faChartPie} /> Stats
            </Button>
          </ButtonGroup>
          <ButtonGroup className="header-btn-grp">
            <Button
              variant="light"
              onClick={() => this.setState({ spoilerViewType: "default" })}
              active={spoilerViewType === "default"}
            >
              Default
            </Button>

            <Button
              variant="light"
              onClick={() => this.setState({ spoilerViewType: "column" })}
              active={spoilerViewType === "column"}
            >
              <FontAwesomeIcon icon={faColumns} /> Color Columns
            </Button>

          </ButtonGroup>
          <Form className="set-input">
            <Form.Label htmlFor="setName">Set Name</Form.Label>
            <Form.Control
              id="setName"
              defaultValue={this.state.set}
              onChange={(e) => this.changeSet(e.target.value)}
            />

          </Form>
          <CardSpoilerGrid
            cards={cards}
            spoilerViewType={spoilerViewType}
            editCard={this.editCard}
            deleteCard={this.deleteCard}
          />
        </div>
        <EditCardForm
          card={editingCard}
          prependNewCard={this.prependNewCard}
          clearEdit={this.clearEdit}
        />
        <DeleteCardBox
          card={deletingCard}
          clearDelete={this.clearDelete}
          destroyCard={this.destroyCard}
        />
      </div>
    );
  }
}

export default App;
